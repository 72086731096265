<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template v-if="$permissionAbility(EMPLOYEE_TYPES_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="restoreSelected"
              :disabled="!isRowChecked"
            >
              Restore Selected
            </b-button>
          </template>
          <template v-if="$permissionAbility(EMPLOYEE_TYPES_DELETE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-on:click="deleteSelected"
              :disabled="!isRowChecked"
            >
              Delete Selected
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :line-numbers="false"
        mode="remote"
        @on-selected-rows-change="selectionChanged"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'name', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :select-options="{ enabled: true }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: head -->
          <span v-if="props.column.field === 'head_user'">
            <template v-if="props?.row?.head_id != null">
              <b-avatar :src="props?.row?.head?.data?.avatar" class="mr-1" />
              <span class="text-nowrap">{{
                props?.row?.head?.data?.name
              }}</span>
            </template>
          </span>

          <span v-if="props.column.field === 'format_departments_count'">
            <b-badge class="ml-4" variant="light-primary">
              {{ props?.row?.departments_count }}
            </b-badge>
          </span>

          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(EMPLOYEE_TYPES_EDIT, permissions)">
              <span @click="restore(props?.row?.id)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="RefreshCcwIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Restore"
                    size="16"
                />
              </span>
            </template>

            <template v-if="$permissionAbility(EMPLOYEE_TYPES_DELETE, permissions)">
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete Permanently"
                    size="16"
                />
              </span>
            </template>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '15', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {

  EMPLOYEE_TYPES_CREATE,
  EMPLOYEE_TYPES_EDIT,
  EMPLOYEE_TYPES_DELETE,

} from "@/helpers/permissionsConstant";

export default {
  name: "EmployeeTypeTrashed",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      EMPLOYEE_TYPES_CREATE,
      EMPLOYEE_TYPES_EDIT,
      EMPLOYEE_TYPES_DELETE,

      modelType: "",
      name: "",
      selectHeadId: "",
      headIdOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Description",
          field: "description",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      selectedRows: [],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isRowChecked: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  methods: {
    showModal() {
      this.$bvModal.show("modal-employee-types-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-employee-types-form");
      this.resetModal();
    },
    resetModal() {
      this.name = "";
      this.selectHeadId = "";
    },
    selectionChanged(params) {
      if (params?.selectedRows.length == 0) {
        this.isRowChecked = false;
        this.selectedRows = [];
      } else {
        this.isRowChecked = true;
        this.selectedRows = params?.selectedRows;
      }
    },
    async restore(id) {
      try {
        await this.$api.post(
          "api/employee-types/restore",

          {
            employee_type_id: id,
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfuly Restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        html: `This Action Is Irreversible! <br> Are You Sure, You Want to Delete ${row.name} Permanently?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post(
              "api/employee-types/force-delete",

              {
                employee_type_id: row.id,
              }
            );

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Permanent Deletion Successful ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async restoreSelected() {
      const employeeTypeIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      try {
        await this.$api.post(
          "api/employee-types/restore-multiple",

          {
            employee_type_ids: employeeTypeIds,
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Selected Employee Types Successfully Restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async deleteSelected() {
      const employeeTypeIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      this.$swal({
        title: "Warning!",
        text: "This action is irreversible! Are you sure to delete these employee types permanently?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post("api/employee-types/force-delete-multiple", {
              employee_type_ids: employeeTypeIds,
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Permanent Deletion Successful ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getEmployeeTypes(params) {
      return await this.$api.get("api/employee-types/trashed", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async getUsers() {
      return await this.$api.get("api/users/all?include=designation");
    },

    async loadItems() {
      try {
        const [employeeTypes] = await Promise.all([
          this.getEmployeeTypes({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = employeeTypes?.data?.data;
        const meta = employeeTypes?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
